export default {
  id: '',
  geneeaId: '',
  entityUuid: '',
  title: '',
  slug: '',
  image: {},
  imageId: null,
  relatedArticlesIds: [],
  relatedArticles: [],
  additionalInfo: {
    event: {
      address: null,
      country: null,
      district: null,
      endDate: null,
      geo: null,
      region: null,
      startDate: null
    },
    location: {
      address: null,
      country: null,
      district: null,
      geo: null,
      population: null,
      region: null,
      type: null
    },
    organisation: {
      address: null,
      alternateName: null,
      country: null,
      district: null,
      region: null,
      logoImage: {},
      logoImageId: null
    },
    person: {
      birthDate: null,
      birthPlace: null,
      deathDate: null,
      deathPlace: null,
      familyName: null,
      givenName: null,
      hasOccupation: null
    },
    product: {
      manufacturer: null,
      model: null
    }
  },
  createdAt: '',
  modifiedAt: null,
  description: '',
  type: ''
}
